
.Test-carosel-main{
    display: flex;
    flex-direction: column;
    padding: 3rem 0px;
}
.Test-carosel-container{
    padding: 2rem 0rem;
    width: min(100%,80%);
    margin-inline: auto;
}
.testimonial-h2{
    text-align: left;
    padding: 1rem 3rem;
    font-size: 1.5rem;
    text-transform: uppercase;
}

.carosel-testimonial-container{
    display: flex !important;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
}
.carosel-testimonial-container-pic{
    width: 100%;
    display: flex;
    padding: 3rem 4rem;
    gap: 40px;
    justify-content: center;
    
}
.carosel-testimonial-container-pic img{
    width: 30%;
    height: 80%;
}

.carosel-testimonial-para{
    padding: 0rem 4rem;
    color: rgb(125, 125, 125);
    font-size: 1.2rem;
    text-align: center;
}
.quote-icons{
    width: 60px !important;
    height: 50px !important;
}
.carosel-testimonial-container h4{
    color: rgb(167, 164, 164);
    font-size: 1.5rem;
    text-transform: uppercase;
}
.testimonials-img{
    width: 300px !important;
    height: 300px !important;
    border-radius: 50%;
    outline: 3px solid black; 
    object-fit: cover;
}

@media (max-width:768px) {
    .testimonial-h2{
        text-align: center;
        font-size: 1rem;
    }
    .carosel-testimonial-container h4{
        font-size: 1rem;
    }
    .testimonials-img{
        width: 200px !important;
        height: 200px !important;
    }
}

@media (max-width:767px) {
    .carosel-testimonial-container-pic{
        padding: 1rem;
        gap: 20px;
    }
    .carosel-testimonial-container-pic img{
        width: 50%;
    }
    .carosel-testimonial-para{
        padding: 0.25rem;
        font-size: 1rem;
    }
    .Test-carosel-container{
        padding: 0rem 2rem 4rem 2rem;
    }

    .icon{
        font-size: 15px;
    }
    .quote-icons{
        width: 30px !important;
        height: 30px !important;
    }
    .testimonial-h2{
        padding: 1rem;
        font-size: 1rem;
        text-align: center;
    }
    .testimonials-img{
        width: 100px !important;
        height: 100px !important;
        outline: 1.5px solid black; 
    }
    .next-btn{
    
        display: none !important;
    }

}