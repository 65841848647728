@font-face {
    font-family: Imported;
    src: url('../../Assets/fonts/conthrax-sb.otf');
}
.product-header-display {
    display: flex;
    flex-direction: column;
    background-image: url('../../Assets/product/productBg.png');
    background-size:cover;
    background-repeat: no-repeat;
    gap: 5px;
}

.product-header-topic {

    display: flex;
   justify-content: center;
    width: max-content;
    margin: 0% 0;
    padding: 4rem 0rem 0 4rem;

}
.product-header-topic h2{
    font-size: 1.5rem;
    margin: 0;
    text-align: center;
    color: white;
    text-transform: uppercase;
    text-shadow: 2px 2px 4px rgba(243, 208, 208, 0.5);
    letter-spacing: 2px;
    font-weight: bold;
    font-family: Imported;
    /* position: relative; */
 
}
.product-header-topic h2::after{
    /* content: "";
    width: 100%;
    height: 110px;
    position: absolute;
    top: 10%;
    left: 0;
    background-image:url('../../Assets/underLine2.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center; */

}
.product-header-topic img{
    width: 100%;
    height: 50px;
    filter: brightness(10);
    display: block;
}
.product-header-description{
    margin: 1%;
    padding: 0px 3rem;
    font-size: 15px;
    color: white;
}

.product-header-description p{
    text-align: justify;
    padding: 0rem 0rem;
    color: rgb(175, 174, 174);
    font-family: poppins;
}


@media (max-width:1023px) {
    .product-header-topic {
        font-size: 2rem;
    }
}
@media (max-width:768px) {
    .product-header-topic {
        font-size: 2rem;
    }
}
@media (max-width:767px) {
    .product-header-topic {
        padding: 1rem 0 0 0;
    }
    .product-header-topic h2{
        font-size: 1.5rem;
    }

    .product-header-topic img {
        width: 55%;
        padding: 0 0 0 5.5rem;
    }
    .product-header-topic{
        width: 100%;
    }
    .product-header-description p{
        font-size: 0.7rem ;
        padding: 0 0;
    }
    
}

/* @media (max-width:375px) {
    .career-header-topic{ 
        width: 100%;
    }
    .career-header-topic h2{
        padding: 3% 0% 0 0;
        margin: 0;
        font-size: 1.5rem;
        width: 100%;
    }
    .career-header-description p{
        text-align: justify;
        padding: 0rem 1rem;
        font-size: 0.5rem;
    }
    .career-header-topic img{
        width: 65%;
        padding: 0 0 0 3.5rem;
    }
    
} */