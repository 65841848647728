@font-face {
    font-family: Imported;
    src: url('../../Assets/fonts/conthrax-sb.otf');
}
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.mainDisplay-display {
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('../../Assets/home/mainBg.png');
    background-repeat: no-repeat;
   
    padding: 5rem 0rem;
    background-size: cover;
    height: auto;

}
.main-display-para{
    text-align: justify;
    font-size: 1rem;
    padding: 0 1rem 0 0;
    font-family: 'Poppins';
}

.mainDisplay-picture {
    flex-basis: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.mainDisplay-picture img{
    width: 100%;
    object-fit: contain;
}

.mainDisplay-innerContent {
    flex-basis: 50%;
    display: flex;
    flex-direction: column;
    padding: 0rem 5rem;
}

.mainDisplay-topic {
    font-family: Imported;
    font-size: 4rem;
    font-weight: bold;
    text-transform: uppercase;
    background-size: cover;
    background-image: linear-gradient(-225deg,rgb(22, 22, 24),rgb(22, 22, 24),rgb(105, 100, 104));
    background-clip: text;  
    -webkit-text-fill-color: transparent;
}
.mainDisplay-subtopic h4{
    font-size: 1.15rem;
    font-family: Imported !important;
    /* padding: 0 1rem; */
}

@media (max-width:768px) {
    .mainDisplay-display{
        padding: 0;
        height: auto;
    }
    .mainDisplay-topic{
        font-size: 3rem;
    }
    .main-display-para{
        font-size: 0.75rem;
        padding: 0 1rem 0 0;

    }
    .mainDisplay-subtopic{
        margin: 0;
        font-size: 0.75rem;
    }
    .break-tag{
        display: none;
    }
    .mainDisplay-innerContent{
        padding: 0 1rem;
    }
}

@media (max-width:767px) {
    .mainDisplay-display{
        flex-direction: column;
        padding: 0;
       
    }
    .mainDisplay-topic{
        font-size: 1.5rem;
        text-align: center;
    }
    .mainDisplay-innerContent{
        align-items: center;
        padding: 1rem;
    }
    .mainDisplay-subtopic h4{
        text-align: center;
        font-size: 1rem;
    }
    .main-display-para{
        font-size: 1rem;
        margin: 0;
        padding: 0 1rem 0 1rem;

    }
    .mainDisplay-picture{
        width: 80%;
        margin-inline: auto;
    }
    .mainDisplay-picture img{
        width: 100%;
        
    }
    .break-tag{
        display: none;
    }
}