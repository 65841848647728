
.career-opportunity{
    display: flex;
    flex-direction: column;
    /* background-color: red; */
    gap: 20px;
    padding: 2rem 0px;
    width: min(96%,85.5rem);
    margin-inline:auto ;
}
.career-opportunity-header{
    display: flex;
    flex-direction: column;
    font-size: 1rem;
}
.career-opportunity-header h3{
    font-size: 2rem;
    text-transform: uppercase;
    text-shadow: 0px 4px 4px rgb(121, 121, 121);
}
.career-discussion-content{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 20px;
    padding: 0 0rem;
}

.career-opportunity-card{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 2rem 1.5rem;
    box-shadow: 0px 5px 5px grey;
    border-radius: 10px;
    /* background-color: red; */
}
.career-opt-card-header{
    font-size: 1rem;
    text-transform: uppercase;
}
.career-opt-card-header h3{
    text-transform: uppercase;
}
.career-opt-card-para{
    text-align: justify;
    padding: 0 1.5rem;
    font-size: 1rem;
    /* background-color: red; */
}

.career-opt-card-pic img{
  object-fit: contain;
  width: 100px;
  height: 90px;
}

@media (max-width:768px) {
    .career-discussion-content{
        grid-template-columns: repeat(2,1fr);
    }
}

@media (max-width:767px) {
    .career-opportunity{
        padding: 1rem;
    }
    .career-opportunity-header{
        font-size: 1rem;
        text-align: center;
    }
    .career-opportunity-header h3{
        font-size: 1rem;
    }
    .career-discussion-content{
        grid-template-columns: 1fr;
    }
    .career-opportunity-card{
        padding: 0.5rem 0rem;
    }
}