.career-gallery-display{
    display: flex;
    flex-direction: column;
    box-shadow: 0 0px 5px grey;
    border-radius: 20px;
  
    background-color: red;
     padding: 2rem 0px;
    margin-inline: auto;
    /* margin-top: 2%;
    margin-bottom: 2% */
}
.career-gallery-container-one{
    display: flex;
}
.career-gallery-container-one-content{
    flex-basis: 62.7%;
    padding: 0rem 4rem;
}
.career-gallery-container-one-content h2{
    font-size: 2rem;
    background-color: white
}
.career-gallery-container-one-content p{
    font-size: 1.5rem;
    background-color: white;
}


.career-gallery-container-one-img{
    flex-basis: 37.3%;
    border-radius: 10px;
   
  
}
.career-gallery-container-one-img img{
     height: 100%;
     width: 100%;
}
.career-gallery-container-one-content h2{
    text-align: left;
}
.career-gallery-container-one-content p{
    text-align: left;
}

/* .career-gallery-description-p-tag{
    background-color: red !important;
} */

.career-gallery-container-two-content{
    display: flex;
    grid-template-columns: repeat(3,1.5fr);
    gap: 0;
}
.career-gallery-container-two-content img{
    width: 33.4%;
}
#corner1{
    border-top-right-radius: 15px;
}
#corner2{
    border-bottom-left-radius: 15px;
}
#corner3{
    border-bottom-right-radius: 15px;
}
.grid-container{
    padding: 2rem 0px;
}

.grid-wrapper{
    display: grid;
    grid-template-columns:repeat(3,1fr);
    grid-auto-rows: 280px;
    width: min(96%,85.5rem);
    margin-inline: auto;
    overflow: hidden;
    border-radius: 20px;
    box-shadow: 0px 0px 10px  rgb(190, 190, 190);
   
}

.grid-items >img{
    width: 100%;
    height: 100%;
    object-fit: cover;

}
.grid-wrapper div:first-child{
    grid-column: span 2;
    padding: 2rem;
    box-sizing: border-box;
}
.grid-items h2{
    font-size: 2rem;
}
.grid-items p{
    font-size: 17px;
    color: gray;
    text-align: left;
}

@media (min-width:768px) and (max-width:1024px) {

    .career-gallery-container-one-content p{
        font-size: 0.75rem;
        padding: 0;
        margin: 0 0 2% 0;
     }
     .grid-items h2{
        font-size: 24px;
        /* text-align: center;  */
    }
    .grid-items p{
        font-size: 16px;
        color: gray;
        text-align: left;
    }
    .grid-wrapper{
     
        grid-auto-rows:220px;

    }
    .grid-wrapper div:first-child{
   
        padding: 1rem;
        box-sizing: border-box;
    }
   
     
}
@media (max-width:767px) {
    .grid-wrapper{
        grid-template-columns:1fr;
        grid-auto-rows: auto;
        gap: 10px;
    }
    .grid-wrapper div:first-child{
        grid-column: span 1;
        padding: 1rem;
        box-sizing: border-box;
    }
    .grid-items h2{
        font-size: 20px;
        text-align: center;
    }
    .grid-items p{
        font-size: 16px;
        color: gray;
        text-align: justify;
    }
    
    .career-gallery-container-one-content{
        padding: 0;
        
    }
    .career-gallery-container-one-content h2,p{
        text-align: center;
    }
    .career-gallery-container-one{
        flex-direction: column;
        gap: 10px;
        padding: 0 0.5rem;
    }
    .career-gallery-container-one-img img {
        width: 100%;
        border-radius: 15px;
    }
    .career-gallery-container-two-content{
        flex-direction: column;
        gap: 10px;
        padding: 0.5rem 0.5rem;
    }
    .career-gallery-container-two-content img{
        width: 100%;
        border-radius: 15px;
    }
}