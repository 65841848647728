@font-face {
    font-family: Imported;
    src: url('../../Assets/fonts/conthrax-sb.otf');
}
@import url('https://fonts.googleapis.com/css2?family=Bungee+Outline&display=swap');
.WhyTorus-container{
    display: flex;
    flex-direction: column;
    width: min(96%,85.5rem);
    margin-inline:auto ;
}
.heading{
    text-transform: capitalize;
    text-shadow: 0px 4px 4px rgb(121, 121, 121);
    font-size: 2rem;
}
.WhyTorus-container h2{
    padding: 1rem 0rem 2rem 1rem;
    font-size: 2rem;

}
.why-torus-content-container{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 20px;
}
.why-torus-content{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background-color: red; */
    box-shadow: 0px 0px 5px grey;
    border-radius: 10px;
    padding: 2rem 4rem;
    margin: 2%;
    text-align: justify;
    /* gap: 10px; */
}
.why-torus-content h2{
    font-size: 1.5rem;
    padding: 0.5rem;
    text-transform: uppercase;    
}
.why-torus-content-abs{
    position: absolute;
    top: 0%;
    left: 0%;
    transform: translate(20%,-50%);
 
    width: 100px;
    height: 100px;
    padding: 0;
    margin: 0;
    font-size: 5rem;
    color:white;
    text-shadow: -15px 10px 5px #b4b9be;
    font-family: Imported;
    /* font-style: oblique; */
    -webkit-text-stroke: 3px black;
    /* font-family: "bungee outline"; */
    /* background-color: blue; */
    
}
.why-torus-para{
    font-size: 1rem;
}

@media (max-width:768px) {
    .why-torus-content{
        padding: 1rem;
    }
    .WhyTorus-container h2{
        font-size: 1.5rem;
    }
}

@media (max-width:767px) {
    .why-torus-content-container{
        grid-template-columns: 1fr;
        /* margin: 2% 10%; */
        gap: 30px;
    }
    .WhyTorus-container h2 {
        text-align: center;
        font-size: 1.25rem;
    }
    
}