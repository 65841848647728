.Bussiness-display{
    height: auto;
}
.Bussiness-inner{
    padding: .5rem 5rem;
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-radius: 20px;
    color: grey;
}

.Bussiness-upper{
    display: grid;
    grid-template-columns: 2fr 2fr;
    gap: 5px;
}

.Bussiness-header h2{
    text-align: left;
    padding: 0px 10px;
    margin: 5%;
    color: black !important;
    font-size: 1.5rem;
    font-weight: bold;
    text-transform: uppercase;
}

.bussiness-head{
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1rem;
    color: black !important;
}

.bussiness-para{
    text-wrap: wrap;
    text-align: justify;
}

@media (max-width:768px) {
    .Bussiness-inner{
        padding: 0rem 1rem;
    }
    .bussiness-para{
        font-size: 0.75rem;
        padding: 0 1rem 0 0rem;

    }
   .Bussiness-header h2{
    font-size: 1rem;
    text-align: center;
   }
}

@media (max-width:767px) {
    .Bussiness-upper{
        grid-template-columns: 1fr;
    }
    .Bussiness-header{
        text-align: center;
    }
    .Bussiness-inner{
        align-items: center;
    }
    .bussiness-para{
        font-size: 1rem;
        padding: 0;
    }
    .Bussiness-header{
            font-size: 1rem;
    }
    .bussiness-head{
        text-align: center;
    }
}