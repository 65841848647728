@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.torus-content-display{
    display: flex;
    /* padding: 3rem; */
    width: min(100%,90%);
    margin-inline: auto;
    gap: 80px;
}
.torus-content-con{
    flex-basis: 50%;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
}
.torus-content-con h2{
    /* margin: ; */
    /* padding: 0;  */
    font-size: 1.5rem;
}
.torus-content-con p{
    text-align: left;

    padding: 1rem 0.5rem 0 1rem;
    font-size: 1rem;
    color: black;
    font-family: 'Poppins';
}

.torus-content-pic{
    flex-basis: 50%;    
}
.torus-content-pic img{
    width: 100%;
    height: 100%;
    object-fit: fill;
}

@media (max-width:768px) {
    .torus-content-con p{
        font-size: 0.70rem;
        padding: 0;
    }
    .torus-content-con h2{
        padding: 0;
        margin: 0;
    }
    .torus-content-pic{
        display: flex;
        justify-content: center;
    }

}
@media (max-width:767px) {
    .torus-content-display{
        flex-direction: column;
        gap: 10px;
        padding: 0.5rem;
    }
    .torus-content-con{
        height: 100%;
        justify-content: space-between;
        
    }
    .torus-content-con p{
        padding: 0;
    }
    .torus-content-con h2{
        padding: 1rem;
        text-align: center;
    }
    .torus-content-pic{
        justify-content: flex-start;
    }
}