.admin-main-console{
    height: 100vh;
    overflow: hidden;
    position: relative;

}
.admin-console{
    width: 100%;
    height: 88%;
    display: flex;
  
}
.admin-display{
    width: 80%;
    height: 100%;
    background-color: #d5d5d5;
    overflow-y: auto;
   
}

@media (max-width:767px) {
    .admin-display{
        width: 100%;
       
    }
}