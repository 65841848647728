.scroll-content{
    /* background-color: red; */
    width: min(100%,90%);
    margin-inline: auto;
    padding: 1rem;
}
.scroll-title{
    font-size: 8rem;
    letter-spacing:30px;
    /* background-color: aliceblue; */
    margin: 0 0;
    padding: 0 0;
    text-align: center;
    color: #eaeaea;
    text-transform: uppercase;
}
.scroll-pic{
    width: 100%;
    height: 300px;
    object-fit: cover;
}
@media (max-width:768px) {
    .scroll-pic{
        height: 250px;
    }
    .scroll-title{
        font-size: 6rem;
        letter-spacing: 10px;
    }
    /* .scroll-thumb{
        background-color: red;
    } */
}
@media (max-width:767px) {
    .scroll-title{
        font-size: 2rem;
    }
}