.carosel-benefit-container{
    padding: 0;
    /* background-color: blue; */
    min-width:500px;
    height: 70vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}
.carsel-benefit-header{
    font-weight: 600;
    font-size: 1.5rem;
    text-transform: uppercase;
}
.carosel-benefit-para{
    text-align: justify;
    font-size: 1rem;
    color: grey;
}
.carosel-benefit-pic{
    width: 90%;
    height: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: red; */
}
@media (max-width:768px) {
    .carosel-benefit-container{
        min-width: 300px;
        /* margin-inline: auto; */
        height: 50vh;
    
    }
    .carsel-benefit-header{
        font-size: 1rem;
    }
    .carosel-benefit-pic img{
        width: 50%;
    }
    .carosel-benefit-para{
        font-size: 0.75rem;
        padding: 1rem;
    }
}

@media (max-width:767px) {
    .carosel-benefit-container{
         min-width: 300px;
         margin-inline: auto;
         height: 60vh;
        /* background-color: yellow; */
        /* background-color: aqua; */
    }
    .carosel-benefit-pic{
        width: 80%;
    }
    .carosel-benefit-pic img{
        width: 40%;
    }
    .carosel-benefit-para{
        font-size: 1rem;
    }
}