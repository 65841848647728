.product-content{
    background-color: rgb(255, 255, 255);
    display: flex;
    padding: 1rem 5rem;
    gap: 20px;
}

.Product-content-picture{
    flex-basis: 50%;

    width: 50%;

    display: flex;
    align-items: center;
    justify-content: center;
}
.Product-content-picture img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.product-content-container{
    flex-basis: 50%;
    padding: 0.5rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.product-content-container h1{
    margin: 0rem;
    padding: 0rem;
}
.product-content-container p{
    font-size: 1rem;
    text-align: justify;
    color:grey;
}

.product-content-grid{
    display: grid;
    grid-template-columns: 2fr 2fr;
}

.product-content-container-one h4{
    padding: 0.2rem ;
    margin: 0rem;
    font-size: 1.25rem;
}
.product-content-container-one h5{
    padding: 0.2rem;
    margin: 0.5rem 0;
    color: grey;
    font-weight: 500;
    font-size: 1rem;
}

.product-content-btn-div{
    margin: 1% 0%;
    padding: 0.5rem 0.5rem;
}

.prouct-content-btn{
    padding: 1rem 3rem;
    border-radius: 10px;
    background-image: linear-gradient(125deg ,black,black,black,grey);
    color: white;
    text-transform: uppercase;
    cursor: pointer;
}

.prouct-content-btn:hover{
    /* background-image: linear-gradient(125deg ,grey,grey,black,black); */
}
.popup-actions{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 1%;
    gap: 20px;
}
.download-btn {
    padding: 0.75rem 2rem;
    border: none;
    border-radius: 10px;
    width: 100%;
    color: white;
    text-transform: uppercase;
    font-weight: 600;
    transition: all 0.6s ease;
    background-color: rgb(175, 175, 175);
    display: flex;
    align-items: center;
    justify-content: center;
}

.download-btn:hover{
    background-image: linear-gradient(125deg ,grey,grey,black,black);
}

.second-form-2{
    background-color: rgb(86, 159, 255);
    width: 20%;
    height: 20%;
    color: white;
    text-transform: uppercase;
}
.btns{
    display: flex;
    align-items: center;
    justify-content: center;
}
.second-form-2:hover{
    background-color: rgb(130, 181, 247);
    color: black;
}



@media (max-width:768px) {
    .product-content-container h1{
        font-size: 1rem;
    }
    .product-content-container p{
        font-size: 0.75rem;
    }
    .product-content-container-one h4{
        font-size:1rem;
    }
    .product-content-container-one h5{
        font-size: 1rem;
    }
    .prouct-content-btn{
        padding: 0.4rem 1.5rem;
    }
}

@media (max-width:767px) {
    .product-content{
        flex-direction: column;
        padding: 1rem;
        align-items: center;
    }
    .product-content-container{
        padding: 0;
    }
    .product-content-container h1{
        text-align: center;
    }
    .product-content-btn-div{
        display: flex;
        /* align-items: center; */
        justify-content: center;
        padding: 1rem 0 0 0;
        margin: 2% 0 0 0;
    }
    .Product-content-picture{
        width: 80%;
        height: 80%;
    }
}


