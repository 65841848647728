.blog-page{
    background-color: grey;
    height: 100%;
    padding: 2rem 0px;
    box-sizing: border-box;
    overflow-y:auto;
}
.blog-wrapper{
    width: min(95%,70.5rem);
    margin-inline: auto;
}
.blog-container{
 width: 100%;
 height: 100%;
 background-color: white;
 padding: 2rem 3rem;
 box-sizing: border-box;
}
.blog-container h1{
    padding: 0;
    margin: 0;
    font-size: 20px;
}
.blog-img-cont{
 
  margin:10px 0px;
}
.blog-img-cont img{
    height:500px;
    width:100%;
    margin-inline: auto;
}
.para-cont p{
    text-align: justify;
}
@media (max-width:767px)
{
    .blog-img-cont img{
        height: 200px;
    }
    .blog-page{
        padding: 1rem 0px;
    }

    .blog-container{
        padding:1rem ;
    }
}