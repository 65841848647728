.sideBar-main{
    /* background-color: red; */
    width: 20%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 2rem 2rem;
    box-sizing: border-box;
    transform: translateX(0%);
    position: relative;
}


.list{

    font-size: 20px;    
    margin-bottom: 15px;
    padding: 0.75rem 1rem; 
    box-sizing: border-box;
    display: flex;
    gap: 15px;
    align-items: center;
    transition: 0.6s;
    cursor: pointer;
  
}
.list span{
    padding: 0 ;margin: 0;
}
.list h4{
    padding: 0;margin: 0;
    font-size: 20px;
    font-weight: 400;
}

.list:hover{
    background-color: black;
    color: white;
    border-radius: 10px;
}
.list-active{
    background-color: black;
    color: white;
    border-radius: 10px;
}
.close-btn-sidebar{
    display: none;
}

@media  (max-width: 767px) {
    .sideBar-main{
      position: absolute;
      /* background-color: black;
      color: white; */
      background-color: white;
      top: 0;
      right: 0;
      width: 60%;
      z-index:111;
      padding: 1rem;
      height: 100vh;
      transform: translateX(100%);
      transition: 0.6s;
    
    }
    .sidebar-close{
        transform: translateX(0%);
    }

    .close-btn-sidebar{
        display: block;
        font-size: 30px;
    }
    /* .list-active{
        background-color: white;
        color: black;
        border-radius: 10px;
    }

    .list:hover{
        background-color:white;
        color: black;
        border-radius: 10px;
    }
     */
}