.Dashboard-display{
  width: 100%;
  height: 100%;
  padding: 1rem ;
  box-sizing: border-box;
}
.Dashboard-components{
    background-color: white;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}