
.gallery-header{
  font-family: Imported;
  font-size: 1.5rem;
}
.gallery-content-p{
  font-family: 'Poppins';
  font-size: 16px;
  text-align: justify;
}
.gallery{
    padding: 3rem 0px;
    box-sizing: border-box;
 }
 .gallery-wrapper{
     width: min(95%,80.5rem);
     margin-inline: auto;
     position: relative;
 }
 .swiper {
 
    padding: 2rem 1rem !important;
  
   }
   
   .swiper-slide {
     text-align: center;
     font-size: 18px;
     width: 360px;
     border-radius: 10px;
     height:370px !important;
   
     background-size:cover ;
     background-repeat: no-repeat;
     background-attachment: scroll;
     transition: transform 0.6s;
   }
   .swiper-slide:hover{
    transform: scale(1.1) !important;
  
   }
   
  
   .nav-cont {
 
  position: absolute;
  right: 10px;
  display: flex;
  justify-content: space-between;
  min-width:110px;
   }
   .nav-cont img:nth-child(1){
     transform: rotate(180deg);
   }
  
   .shorts-overlay{
     background-image: linear-gradient(rgba(161, 161, 161, 0.192),rgba(0, 0, 0, 0.514));
     width: 100%;
     color: white;
     height: 100%;
     display: flex;
     align-items: end;
     padding: 2rem;
     box-sizing: border-box;
     border-radius: 10px;
   }

   .gallery-wrapper p {
    padding: 1rem 0 0rem 0;
    font-size: 1.2rem;
   }

   @media (max-width:767px) {
    .gallery-wrapper p{
        font-size: 1rem;
        text-align: left;
    }
   }