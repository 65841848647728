.Product-page-display{
    display: flex;
    flex-direction: column;
  
}

.product-pagination{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem 0rem;
}
.product-pagination ul{
    display: flex;
    list-style-type: none;
    gap: 10px;
    align-items: center;
}

.product-record-num{
    display: flex;
    gap: 10px;
    flex-direction: row;
}

.product-deactive{
    color: grey;
    pointer-events: none;
}
.product-active{
    color: black;
}

.product-page-num{
    text-align: center;
    border: 1px solid black;
    border-radius: 10px;
    color: white !important;
    padding: 0.2rem 0.7rem;
    background-image: linear-gradient(125deg ,rgb(0, 0, 0),rgb(0, 0, 0),rgb(180, 179, 182));
}
.product-color{
    /* background-image:linear-gradient(to right, white,white); */
    color: black;
    text-align: center;
    border: 1px solid black;
    border-radius: 10px;
    padding: 0.2rem 0.7rem;

}

.product-icon-num{
    padding: 0.8rem;
}

.pro-cont{
    display: flex;
    gap: 20px;
}