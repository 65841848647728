.contact-main-header {
    background-color: rgb(255, 255, 255);
    /* height: 100vh; */
}

.contact-header {
    width: 100%;
    height: 40vh;
    background-image: url('../Assets/contact/contactus.png');
    background-repeat: no-repeat;
    background-size: cover;
}

.contact-header-address-bar {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: space-evenly;
    transform: translate(0%, -50%);
}

.contact-address {
    padding: 2.5rem 0rem;
    background-color: white;
    border-radius: 10px;
    width: 20%;
    min-height: 200px;
    box-shadow: 0px 0px 5px rgb(194, 194, 194);
}

.contact-address h4 {
    padding: 1rem;
    margin: 0;
    font-size: 1.5rem;
    color: #656565;
}

.contact-address div {
    padding: 0rem 1.5rem;
    font-size: 1.15rem;
    color: #656565;

}

.contact-map {
    display: flex;
    align-items: center;
    justify-content: center;
}

.map-google {
    margin-inline: auto;
}

.contact-form {
    /* background-color: black; */
    display: flex;
    padding: 0rem 3rem;
    gap: 20px;
    align-items: center;
    justify-content: center;
}

.contact-form picture {
    flex-basis: 60%;
    width: 100%;
    /* background-color: black; */
}

.contact-form picture>img {
    object-fit: contain;
    width: 100%;
    height: 80%;
    filter:invert(1) contrast(10);
}

.contact-form-content {
    flex-basis: 40%;
    display: flex;
    flex-direction: column;
 

    /* background-color: red; */
    gap: 10px;
    padding: 0.5rem 0;
}

.contact-cancel-btn{
    font-size: 1.5rem;
}
.contact-form-content h2 {
    /* text-align: center !important; */
    padding: 0 0;
    margin: 0;
    font-size: 2rem;
    text-transform: uppercase;

}

.contact-form-content .contact-form-content-2 {
    text-align: justify;
    padding: 0.5rem 3rem 0 0;
    color: grey;
    font-size: 1.25rem;
    font-weight: 300;
}

.popup {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0.5rem 2rem;
}

.popup-content {
    border-radius: 25px;
    width: auto !important;


}

.popup-header div>h2 {
    padding: 0.5rem 0rem;
    margin: 0%;
    text-transform: uppercase;
    text-align: left;
    width: max-content;
}

.popup-header div {
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.popup-header-cont {
    font-size: 1rem;
}

.popup-header div>div {
    cursor: pointer;
}

.popup-form {
    padding: 0.5rem 0rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 5px;
}

.popup-form label>span>sup {
    color: grey;
}

.popup-form label {
    text-transform: capitalize;
}

.color-red {
    color: red;
}
.color-grey{
    color: grey;
}

.popup-form input {
    /* width: 50%; */
    /* height: 2.5rem; */
    text-align: start;
    padding: 0.75rem;
    border: 2px solid rgb(212, 212, 212);
    outline: none;
}

.popup-comments {
    height: 5rem !important;
    /* width: 50%; */
    border: 2px solid rgb(212, 212, 212);
    outline: none;

    resize: none;
}

.actions {
    display: flex;
    gap: 30px;
    width: 100%;
}

.contact-btn {
    padding: 0.75rem 2rem;
    border: none;
    border-radius: 10px;
    width: 100%;
    background-image: linear-gradient(125deg, rgb(25, 20, 26), rgb(25, 20, 26), rgb(25, 20, 26), rgb(206, 206, 206));
    color: white;
    text-transform: uppercase;
    font-weight: 600;
    transition: all 0.6s ease;
    cursor: pointer;
}

.contact-btn:hover {
    /* background-image: linear-gradient(125deg, rgb(206, 206, 206), rgb(206, 206, 206), rgb(206, 206, 206), rgb(25, 20, 26)); */
    /* color: black; */
}

.contact-get-in-touch {
    padding: 0.75rem 0rem;
    margin-left: 0%;
    border-radius: 10px;
    width: 40%;
    /* background-color: black; */
    /* background-image: linear-gradient(125deg ,rgb(25, 20, 26),rgb(25, 20, 26),rgb(25, 20, 26),rgb(206, 206, 206)); */
    color: black;
    text-transform: uppercase;
    font-weight: 600;
    transition: all 0.6s ease;
    border: 1px solid rgb(163, 163, 163);
    background-color: white;
    text-align: center;
    cursor: pointer;
}

.contact-get-in-touch:hover {
    background-image: linear-gradient(125deg, rgb(25, 20, 26), rgb(25, 20, 26), rgb(25, 20, 26), rgb(206, 206, 206));
    color: white;
}


.contact-icons{
    width: 50px;
    height: 50px;
}
@media (max-width:768px) {
    .contact-form-content h2 {
        font-size: 1.3rem;
    }

    .contact-form-content .contact-form-content-2 {
        font-size: 1rem;
        padding: 0 0.1rem 0 0 ;
        /* background-color: red; */
    }

    .contact-get-in-touch {
        width: 50%;
        padding: 0.5rem 1rem;
    }

    .contact-address {
        padding: 1rem 0.5rem;
    }

    .contact-address h4{
        font-size: 1rem;
    }
    .contact-address div {
        font-size: 0.75rem;
        padding: 0rem 0.2rem;
    }

    .contact-address picture {
        padding: 0.75rem 0rem;
    }
    .contact-icons{
        width: 30px;
        height: 30px;
    }
}

@media (max-width:767px) {
    .contact-header-address-bar {
        margin-top: 100px;
        flex-direction: column;
        gap: 20px;
        transform: translate(0%, 0%);
        padding: 0.5rem 0rem;
        position: relative;
        z-index: 1;
    }

    .contact-address {
        width: 50%;
        /* height: 50vh; */
    }

    .contact-header {
        /* height: 100vh; */
        position: absolute;
        top: 20%;
        z-index: 1;

    }

    .contact-form {
        flex-direction: column;
    }
    .contact-form-content{
        align-items: center;
        /* padding: 1rem; */
    }

    .contact-form-content h2 {
        font-size: 1rem;
        text-align: center;
    }

    .contact-form-content .contact-form-content-2 {
        text-align: justify;
        padding: 0.5rem 0.75rem;
        color: grey;
        /* font-size: 1rem; */
    }

    .contact-get-in-touch {
        padding: 0.5rem;
        width: 60%;
    }


}