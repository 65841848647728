.Benefits-display{
    display: flex;
    flex-direction: row;
    /* height: auto; */
    /* width: 100%; */

    padding: 5.5rem 3rem;
    /* background-color: yellow; */
    position: relative;
    overflow: hidden;
}

.Benefit-img{
    flex-basis: 50%;
    /* background-image: linear-gradient(to right, rgb(204, 202, 202),rgb(204, 202, 202), white,white,white 100%); */
    position: relative;
    /* background: red; */
    /* background-image: url('../../Assets/home/Benefit/image.png'); */
    
}
.Benefit-TAFM{
    letter-spacing: 5rem;
    margin-left: 15%;
}
.Benefits-outer-display{
    flex-basis: 50%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;

}

.color-white{
    color: white;
}
.color-grey{
    color: rgb(204, 202, 202) ;
}

.Benefit-logo{
    position:absolute;
    filter: brightness(1);
    mix-blend-mode:multiply;
    z-index: 10;
    top: 0;
    left: 0;
    transform: translate(-30%,-30%);
    width: 150px;
    height: 150px;
    user-select: none;
    animation: rotate 10s infinite;
}
@keyframes rotate{
    from{
        transform:translate(-30%,-30%) rotate(0deg);
    }
    to{
        transform:translate(-30%,-30%) rotate(360deg);
    }
}

.Benefit-product-img{
    width: 90%;
    height: 90%;
    object-fit: contain;
    mix-blend-mode: multiply;
}

.slick-silde{
    margin: 0;
    background-color: blue;
}

.Benefits-carosel-container{
    background-color: blue;
    text-align: center;
}

.benefit-bg-pic{
    width: 50%;
    object-fit: contain;
    height: 50%;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%,90%);
    bottom: 0;
    opacity: 0.1;
}

@media (max-width:768px) {
    .Benefits-display{
        padding: 3rem;
    }
    .Benefit-img{
        padding: 0 0;
        /* width: 50%; */

    }
    .Benefit-logo{
        width: 100px;
        height: 100px;
        /* aspect-ratio: 1/1; */
    }
    .Benefit-product-img{
        width: 100%;
        height: 100%;
    }
    .Benefits-outer-display{
        width: 50%;
    }
    .Benefit-TAFM{
        letter-spacing: 3rem;
    }
    
}

  @media (max-width:767px) {
    .Benefits-display{
        flex-direction: column;
        padding: 1rem 0;
        align-items: center;
    }
    .Benefit-TAFM{
        font-size: 1rem;
        letter-spacing: 2rem;
    }
    .Benefit-img{
        width: 85%;
    }
    .Benefit-logo{
        width: 100px;
        height: 100px;
    }
    .Benefits-outer-display{
        width: 100%;
        margin-top: 5%;
        /* background-color: red; */
    }
    .Benefit-product-img{
        width: 100%;
        height: 100%;
    }
    .Benefit-product-img{
        /* width: 200px;
        height: 200px; */
    }
    .benefit-bg-pic{
        /* display: none; */
        
    }
  }