.blogs-content-parent{
    display: flex;
    flex-direction: column;
    /* background-color: yellow; */
}
.blogs-content {
    background-color: rgb(255, 255, 255);
    display: flex;
    padding: 1rem 2.5rem;
    gap: 20px;
    /* background-color: red; */
    /* height: 50vh; */
}

.blogs-content-parent h1 {
    padding: 2rem 5rem;
}

.blogs-content-picture {
    flex-basis: 50%;
    /* height: 100%; */
    display: flex;
    justify-content: center; 
    /* width: 50%; */
    width: 500px;
    height: 350px;
    /* background-color: red; */
}

.blogs-content-picture img{
    width: 100%;
    height: 100%;
    object-fit: contain; 
}

.blogs-content-container {
    flex-basis: 50%;
    padding: 0.5rem 1rem;
    /* background-color: blue; */
}

.blogs-content-container h1 {
    margin: 0rem;
    padding: 0rem;
    color:#aaaaaa;
    font-weight: 400;
}

.color-grey {
    color: #aaaaaa;
}

.color-black {
    color: #000000 !important;
    font-size: 3.25rem;
}

.blogs-content-container h3 {
    margin: 0rem;
    padding: 0rem;
    margin-top: 30px;
    font-size: 1.3rem;
    text-transform: capitalize;
    color: rgb(19, 19, 19);
}

.blogs-content-container p {
    text-align: justify;

}
.blog-btn{
    margin-top: 1%;
    padding: 0.5rem 0.75rem;
    font-weight: 700;
    border-radius: 5px;
    box-shadow: 0 0 2px rgb(128, 128, 128);
    width: max-content;
    font-size: 1rem;
    color: #555555;
    cursor: pointer;
}

@media (max-width:768px) {
    .blogs-content{
        padding: 0.5rem 1rem;
    }
    .blogs-content-picture{
        /* width: 100%; */
        padding: 0;
    }
    .blogs-content-picture img{
        width: 100%;
        height: 100%;
        padding: 2.5rem;
    }
    .blogs-content-container-h1{
        font-size: 16px;
        margin: 0 !important;
        padding: 0 !important;
    }
    .blogs-content-container h3{
        font-size: 14px;
        margin: 0;
    }
    .blogs-content-container p{
        font-size: 12px;
        margin: 0;
    }
    
    .blogs-content-parent h1{
        margin: 0;
        padding: 1rem 2rem;
    }
    .blogs-content-container{
        padding: 0.5rem;
    }
}

@media (max-width:767px) {
    .blogs-content {
        flex-direction: column;
    
    }
    .blogs-content-picture{
        width: 100%;
    }
    .blogs-content-container{
        /* display: flex;
        flex-direction: column;
        align-items: center; */
        /* position: relative */
    }
    .blog-btn{
        margin-top: 2%;
      margin-inline: auto;
    }
}