
.login{
    height: 100vh;
    width: 100%;
    /* background-image: linear-gradient(45deg,#d2001a,#7462ff,#f48e21,#23d5ab); */
    background-image: url("../../Assets/loginBg.jpg");
   

    animation: animateColor 12s ease-in-out infinite;
}
@keyframes animateColor{
    0%{
        background-position: 0 50%;
    }
    50%{
        background-position: 100%,50%;
    }
    100%{
    background-position: 0 50%;
    }
}
.wrapper-container-login{

   
    width: min(95%,40.5rem);
    height: 100%;
    margin-inline: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}
.login-form{
    width: 60%;
    /* background-color: rgba(215, 215, 215, 0.614); */
    padding: 4rem 2rem 2rem 2rem;
    box-sizing: border-box;
    position: relative;
    border-radius: 20px;
}
.login-form div{
    display:flex;
    padding: 0;
    margin: 0;
    margin-bottom: 15px;
    height: 50px;
    position: relative;
}
.lock-icon{
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: white;
    font-size: 20px;
    z-index: 11;

}
.login-form  div .login-icon{
    width: 15%;
    background-color: #00254a;
    height: 100%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}
.login-form input::placeholder
{
 color:white;
}
.login-form div input[type="submit"]{
    width: 100%;
    background-color:#00254a;
    padding: 0;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 17px;
    letter-spacing: 1px;
 
}
.login-form div input{
    width: 85%;
    box-sizing: border-box;
    height: 100%;
    padding-left: 1rem;
    margin-bottom: 1rem;
    background-color:#364f6e;
    color: white;
    outline: none;
    border: none;
}
.login-form .login-logo{
    
    background-color:#00254a;
    color: white;
    width:80px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 50px;
    height:80px;
    position: absolute;
    top: 0%;
    border-radius: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
@media(max-width:767px){
    .login-form{
        width: 100%;
    }
}
