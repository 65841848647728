
.settings-cont{
    background-color: white ;
    padding: 2rem  1rem;
    box-sizing: border-box;
}

.setting-operations >* {
    width: 200px;
}

.setting_container{
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}
.setting_container label{
    font-weight: 600;
    font-size: 17px;
    text-transform: capitalize;
}