.footer-display{
    display: flex;
    gap: 5px;
    width: 100%;
    /* background-color: black; */
    background-image: url('../Assets/home/footerBg.png');
    background-repeat: no-repeat;
    background-size: cover;
}
.Footer-logo{
    width: 100%;
    /* height:20%; */
    object-fit: contain;
}

.footer-content{
    flex-basis: 70%;
    justify-content: space-evenly;
    padding: 4rem 2rem;
    display: flex;
    color: white;
}
.footer-menu{
    display: flex;
    justify-content: flex-end;
    gap: 20px;
}
.footer-po{
    cursor: pointer;
    width: max-content;
}
.footer-content-2{
    flex-basis: 30%;
    display: flex;
    flex-direction: column;
    padding: 4rem 1rem;
    gap: 15px;
    color: white;
}
.footer-inner{
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 0 0.5rem;
}
.footer-inner-header{
    font-weight: 600;
    text-align: left;
    text-transform: capitalize;
}
.footer-social{
    display: flex;
    gap: 10px;
    /* background-color: red; */
}

.footer-icons{
    width: 50px;
    height: 50px;
    cursor: pointer;
}

.footer-inside-div{
    padding: 5px 15px 15px 0px  ;
}

@media (max-width:1023px){
    .footer-display{
        flex-direction: column;
        align-items: center;
    }
    .footer-social{
        justify-content: center;
    }

}

@media (max-width:768px){
    .footer-display{
        flex-direction: column;
        align-items: center;
    }
    .footer-content{
        flex-direction: column;
        gap: 30px;
        padding: 4rem 0  0 0;
        justify-content: space-evenly;
        align-items: center;
    }
    .footer-content-2{
        padding: 1rem 4rem;
    }
    .footer-social{
        justify-content: center;
    }

}

@media (max-width:767px){
    .footer-display{
        flex-direction: column;
        align-items: center;
    }
    .footer-content{
        font-size: 10px;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        padding: 2rem 0 0rem 0rem ;
    }
    .footer-menu{
        font-size: 1rem;
        padding: 0rem 0 0 0;
        flex-direction: column;
        align-items: center;
    }
    .footer-content-2{
        padding: 1rem 0rem;
    }
    .footer-icons{
        width: 50px;
        height: 50px;
    }
    .footer-inner{
        padding: 0 2rem;
    }
    
}

.footer-social a {
    display: inline-block;
    text-decoration: none;
}