.testimonials-card-data{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.loader-component{
     width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.loader-component img{
    width: 200px;
    height: 200px;
}