.product-carosel-display{
    padding: 3rem 2rem;
}

.product-carosel-header{
    padding: 1rem 1.5rem;
    font-size: 1.5rem;
    text-transform: uppercase;
}

.slick-prev {
    padding:0.5rem 1.35rem;
    border-radius: 1.35rem;
    left: -40px;
    top: 54%;
}

.slick-next {
    padding:0.5rem 1.35rem;
    border-radius: 1.35rem;
    transform: rotate(180deg);
    right: -30px;
    top: 48%;
}


.slick-slide > div{
    margin: 0px 15px;
}

.product-carosel-container{
    box-shadow: 0px 0px 5px rgb(207, 207, 207);
    border-radius: 20px;
    overflow: hidden;
    height: 670px;
}

.product-carosel-container-picture{
 
    display:flex;
    align-items: center;
    height: 50%;
    justify-content: center;
    background-color: rgb(207, 207, 207);
}

.product-carosel-container h3{
    padding: 0rem 2.5rem;
    font-size: 1.5rem;
    text-transform: uppercase;

}

.product-carosel-container ul >li{
    list-style-type: none;
    padding: 0.30rem 0.50rem 0.25rem 0 !important;
    display: flex;
    /* align-items: center; */
    /* background-color: red; */
    gap: 10px;   
    font-size: 1rem;
    /* text-align: center; */
}
.product-carosel-container ul>li>span{
    text-align: center; 
    padding: 0.1rem 0;
    /* display: flex;
    align-items: center;    */
}
#star-icon{
    color: aliceblue;
    fill: black;
}

@media (max-width:768px){
    .product-carosel-display{
        padding:  0 1rem 1.75rem 1rem;
    }
    .product-carosel-header{
        padding: 1rem;
        font-size: 1rem;
        text-align: center;
    }
    .slick-prev{
        top: 52%;
    }
    .slick-next{
        top: 50%;
        right: -1%;
    }
    .slick-slide > div{
        margin: 0px 5px;
    }
    .product-carosel-container-picture{
        height: 50%;
    }
    .product-carosel-container-picture img{
        height: 100%;
        width: 100%;
    }
    .product-carosel-container h3{
        font-size: 1rem;
    }
    .product-carosel-container{
        height: 40rem;
    }

}


@media (max-width:767px){
    .product-carosel-display{
        padding:  0 1rem 1.75rem 1rem;
    }
    .product-carosel-header{
        padding: 1rem;
        font-size: 1rem;
        text-align: center;
    }
    .slick-prev{
        top: 52%;
        display: none;
    }
    .slick-next{
        top: 40%;
        right: -5%;
        display: none;
    }
    .slick-slide > div{
        margin: 0px 5px;
    }
    .product-carosel-container-picture{
        height: 40%;
        width: 100%;
    }
    .product-carosel-container-picture img{
        height: 100%;
        width: 100%;
    }
    .product-carosel-container{
        height: 35rem;
    }

}